import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import favicon from '../../images/favicon.ico'

// import './styles.scss'

const Layout = ({ settings, seo, date, children }) => {
  const { backgroundColor, isBlogPost } = settings
  const publishDate = isBlogPost ? date.publishDate : ''
  const {
    pageTitle,
    description,
    canonical,
    robots,
    twitterCard,
    twitterSite,
    twitterTitle,
    twitterDescription,
    twitterCreator,
    twitterImage,
    ogTitle,
    ogUrl,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    ogDescription,
    ogSiteName,
  } = seo

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: ogUrl,
      name: ogSiteName,
      alternateName: ogSiteName,
    },
  ]

  if (isBlogPost) {
    schemaOrgJSONLD.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': ogUrl,
              name: ogTitle,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': ogUrl,
              name: ogTitle,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: ogUrl,
        name: ogTitle,
        alternateName: ogSiteName,
        headline: ogTitle,
        image: {
          '@type': 'ImageObject',
          url: ogImage,
          width: ogImageWidth,
          height: ogImageHeight,
        },
        author: {
          '@type': 'Person',
          name: twitterCreator,
          url: twitterSite,
        },
        publisher: {
          '@type': 'Organization',
          name: ogSiteName,
          url: ogUrl,
        },
        datePublished: publishDate,
        mainEntityOfPage: ogUrl,
      }
    )
  } else {
    // Page SEO Schema
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      url: ogUrl,
      name: pageTitle,
    })
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="./src/images/apple-touch-icon.png"
        />
        {/* <link
          rel="shortcut-icon"
          sizes="192x192"
          href="./src/images/android-chrome-192x192.png"
        /> */}
        <link rel="shortcut icon" href={favicon} />
        {/* <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="./src/images/favicon-32x32.png"
        /> */}
        {/* <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="./src/images/favicon-16x16.png"
        /> */}
        <link rel="manifest" href="./src/images/site.webmanifest" />
        <link rel="canonical" href={canonical} />
        <link rel="robots" content={robots} />
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:image:height" content={ogImageHeight} />
        <meta property="og:description" content={ogDescription} />
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:creator" content={twitterCreator} />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:image" content={twitterImage} />
        <meta name="twitter:description" content={twitterDescription} />
      </Helmet>
      <main id="main" className={`bg-${backgroundColor}`}>
        {children}
      </main>
    </Fragment>
  )
}

export default Layout
