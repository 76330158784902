import React from 'react';
import { Link } from 'gatsby';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

library.add(fas);

const Button = ({ props }) => {
  const {
    buttonId,
    buttonClass,
    style,
    type,
    soft,
    outline,
    shape,
    size,
    title,
    text,
    link,
    hasIcon,
    icon,
    iconLocation,
    value,
    noFollow,
    newTab,
    trackingId,
    trackingPosition
  } = props;

  let _style;

  const _outline = (outline) ? 'btn-outline-'+style : '';

  if(!outline) {
    if(style === "link") {
      _style = "link";
    } else {
      _style = (style) ? 'btn-'+style : '';
    }
  }

  const _soft = (soft) ? 'btn-soft-'+style : '';

  let _shape;
  if(shape === "square") {
    _shape = "rounded-0";
  } else if(shape === "pill") {
    _shape = "rounded-pill";
  }

  const _size = (size && size !== "primary") ? 'btn-'+size : '';
  const _noFollow = (!noFollow) ? "nofollow" : '';
  const _newTab = (newTab) ? '_blank' : '_self';

  const _hasIcon = (hasIcon) ? 'btn-icon' : '';
  
  if(type === "link") {
    return (
      <Link
        to={link}
        id={buttonId}
        className={`btn ${_style} ${_soft} ${_outline} ${_shape} ${_size} ${_hasIcon} ${buttonClass}`}
        data-lid={trackingId}
        data-lpos={trackingPosition}
        title={title}
        rel={_noFollow}
        target={_newTab}>
          {hasIcon && iconLocation && (
            <span className="btn-inner--icon">
              <FontAwesomeIcon icon={['fas', icon]} size="lg"/>
            </span>
          )}
          <span className="btn-inner--text">{text}</span>
          {hasIcon && !iconLocation && (
            <span className="btn-inner--icon">
              <FontAwesomeIcon icon={['fas', icon]} size="lg"/>
            </span>
          )}
      </Link>
    )
  } else {
    return (
      <button
        id={buttonId}
        className={`btn ${_style} ${_soft} ${_outline} ${_shape} ${_size} ${_hasIcon} ${buttonClass}`}
        data-lid={trackingId}
        data-lpos={trackingPosition}
        value={value}
        type={type}
      >
        {hasIcon && iconLocation && (
          <span className="btn-inner--icon">
            <FontAwesomeIcon icon={['fas', icon]} size="lg"/>
          </span>
        )}
        <span className="btn-inner--text">{text}</span>
        {hasIcon && !iconLocation && (
          <span className="btn-inner--icon">
            <FontAwesomeIcon icon={['fas', icon]} size="lg"/>
          </span>
        )}
      </button>
    )
  }
}

export default Button
