import React from 'react'
import LazyLoad from 'react-lazyload'
import Column from '../Column/'

import './styles.scss'

const Module = ({ props, settings }) => {
  const {
    moduleId,
    moduleClass,
    verticalSpacing,
    removeTopPadding,
    wrapperWidth,
    background,
    backgroundImage,
    backgroundColor,
    columns,
  } = props

  const navOnHero = settings.navigationOnHero ? 'under-nav' : ''

  let bgColor = ''

  if (!background) {
    bgColor = !!backgroundColor
      ? backgroundColor.replace(/\s+/g, '-').toLowerCase()
      : 'white'
  }

  const modId = moduleId || ''
  let containerWidth = 'container'
  if (wrapperWidth === 'full') {
    containerWidth = 'container-fluid'
  }

  let noTopPadding = ''
  if (removeTopPadding) {
    noTopPadding = 'pt-0'
  }

  return (
    <section
      id={modId}
      className={`module module--${verticalSpacing} ${moduleClass} bg-${bgColor} ${noTopPadding} ${navOnHero}`}
    >
      {background && (
        <LazyLoad>
          <img
            src={backgroundImage.fluid.src}
            className="img-fluid img-as-bg img-bg-vc"
            alt=""
          />
        </LazyLoad>
      )}
      <div className={containerWidth}>
        <div className="row">
          {columns.map(column => (
            <Column key={column.id} props={column} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Module
