import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import './styles.scss'

library.add(fab, fas)

const Footer = ({ company, settings, props }) => {
  const { name, address, email, phone, copyright, socialLinks } = company
  // const { footerMessage } = settings
  const { logo } = props

  const addressPlainText = address.childMarkdownRemark.plainText.replace(
    /\s+/g,
    '+'
  )

  // col-lg-4 col-sm-8 offset-lg-0 col-10 offset-1 mb-4
  // col-lg-2 col-md-4 col-10 mx-auto visit
  // col-lg-2 col-md-4 col-10 mx-auto contact
  // col-lg-2 col-md-4 col-10 mx-auto

  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 mx-auto">
            <Link to="/" title="Home">
              <Img
                fluid={logo.file.fluid}
                alt={name}
                className="footer__logo"
              />
            </Link>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-2 offset-lg-0 col-sm-8 offset-sm-2 mx-auto visit">
            <h5 className="footer__heading">LOCATION</h5>
            <div className="footer__address">
              <a
                href={`https://google.com/maps/place/${addressPlainText}`}
                title="LOCATION"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <address>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: address.childMarkdownRemark.html,
                    }}
                  />
                </address>
              </a>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-0 col-sm-8 offset-sm-2 mx-auto contact">
            <h5 className="footer__heading">CALL / EMAIL</h5>
            <div>
              <ul className="list-unstyled">
                <li>
                  <a href={`tel:${phone}`} className="link" title="Call us">
                    {phone}
                  </a>
                </li>
                <li>
                  <a href={`mailto:${email}`} className="link" title="Email us">
                    {email}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-0 col-sm-8 offset-sm-2 mx-auto">
            <h5 className="footer__heading">CONTACT</h5>
            <div>
              <Link to="/contact" className="link" title="Get Legal Help">
                Get Legal Help
              </Link>
            </div>
          </div>
        </div>
        <div className="row py-4 mt-4 align-items-center social-legal">
          <div className="col-lg-6 mx-auto social-links">
            {socialLinks.map(sl => (
              <a
                key={sl.id}
                href={sl.link}
                title={sl.title}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', sl.icon]} size="lg" />
              </a>
            ))}
          </div>

          <div className="col-lg-6 legal">
            <span className="copyright">{copyright}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
