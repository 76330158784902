import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../Button';

import './styles.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas)

const Card = ({ props }) => {
  const {
    featured,
    link,
    image,
    hasIcon,
    iconStyle,
    icon,
    textAlign,
    headline,
    headlineType,
    details,
    hoverEffect,
    button
  } = props
  const feat = featured ? 'featured' : ''

  let textAlignment;
  if(textAlign && textAlign !== "left") {
    textAlignment = `text-${textAlign}`
  }

  const HeadlineTag = `${headlineType}`
  const hoverEff = hoverEffect || []
  let hover
  if (hoverEff.includes('shadow')) {
    hover += ' shadow-lg'
  }
  if (hoverEff.includes('shift')) {
    hover += ' hover-translate-y-n3'
  }
  if (hoverEff.includes('scale')) {
    hover += ' hover-scale-110'
  }

  return (
    <div className={`card ${feat} ${hover} ${textAlignment}`}>
      {image && <Img fluid={image.file.fluid} backgroundColor={'#eeeeee'} alt={image.alt} />}
      <div className="card-body">
        {hasIcon && (
          <div className="py-5">
            <div className={`icon text-${iconStyle} mx-auto`}>
              <FontAwesomeIcon icon={['fas', icon]} size="lg"/>
            </div>
          </div>
        )}
        {HeadlineTag && link && (
          <Link to={link} className="link" title={headline}>
            <HeadlineTag className="title">{headline}</HeadlineTag>
          </Link>
        )}
        {HeadlineTag && !link && (
          <HeadlineTag className="title">{headline}</HeadlineTag>
        )}
        <div
          className="details"
          dangerouslySetInnerHTML={{
            __html: details.childMarkdownRemark.html,
          }}
        />
        {button && (
          <Button props={button} />
        )}
      </div>
    </div>
  )
}

export default Card
