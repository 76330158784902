import React from 'react'
import Content from '../Content/'

const Column = ({props}) => {
    const {
        columnClass,
        columnWidth,
        columnOffset,
        columnUtility,
        content
    } = props;

    const colOffset = (columnOffset === "no offset") ? "no-offset" : "offset-lg-" + columnOffset;
    return (
        <div className={`${columnClass} col-md-12 col-lg-${columnWidth} offset-md-0 ${colOffset} u-${columnUtility}`}>
            {content.map(con => (
                <Content key={con.id} props={con} />
            ))}
        </div>
    )
}

export default Column
