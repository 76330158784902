import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
      submitted: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'ballentine-contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
      submitted: true,
    })
  }

  render() {
    return (
      <Fragment>
        {this.state.submitted && (
          <div className="alert alert-success" role="alert">
            <strong>Thank you for contacting us!</strong> We will be in touch
            with you soon!
          </div>
        )}
        <form
          name="ballentine-contact"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot"
          className="contact-form"
        >
          <input type="hidden" name="form-name" value="ballentine-contact" />
          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot" onChange={this.handleInputChange} />
            </label>
          </p>

          <div className="form-group">
            <label htmlFor="name">Name: *</label>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="Full Name"
              className="form-control"
              value={this.state.name}
              onChange={this.handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email: *</label>
            <input
              name="email"
              id="email"
              type="email"
              placeholder="name@company.com"
              className="form-control"
              value={this.state.email}
              onChange={this.handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              name="phone"
              type="text"
              id="phone"
              placeholder="(111) 111-1111"
              className="form-control"
              autoComplete="off"
              value={this.state.phone}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="company">Company:</label>
            <input
              name="company"
              id="company"
              type="text"
              placeholder=""
              className="form-control"
              value={this.state.company}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">What can we do for you? *</label>
            <textarea
              name="message"
              id="message"
              type="text"
              placeholder="Provide detail here"
              className="form-control"
              value={this.state.message}
              onChange={this.handleInputChange}
              rows="7"
              required
            />
          </div>

          <div>
            <input
              name="submit"
              className="btn btn-block btn-lg btn-brand3 mt-4"
              type="submit"
              value="Send"
            />
          </div>
        </form>
      </Fragment>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm
