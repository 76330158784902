import React from 'react'
import LazyLoad from 'react-lazyload'
import Column from '../Column/'

import './styles.scss'

const Hero = ({ props, settings }) => {
  const {
    background,
    backgroundImage,
    backgroundColor,
    backgroundImageMask,
    verticalSpacing,
    columns,
    angledBottom,
  } = props

  const navOnHero = settings.navigationOnHero ? 'under-nav' : ''

  let bgColor = ''
  if (!background && !backgroundImageMask) {
    bgColor = backgroundColor ? backgroundColor.replace(' ', '-') : 'white'
  }

  return (
    <section
      className={`module module--${verticalSpacing} hero bg-${bgColor} ${navOnHero} ${angledBottom &&
        'clip-path'}`}
    >
      {background && (
        <LazyLoad>
          <img
            src={backgroundImage.file.fluid.src}
            alt={backgroundImage.file.alt}
            className="img-fluid img-as-bg img-bg-vc"
          />
        </LazyLoad>
      )}
      {backgroundImageMask && <div className="mask bg-dark opacity-8"></div>}
      <div className={`jumbotron mb-0 rounded-0 bg-${bgColor}`}>
        <div className="container">
          <div className="row">
            {columns && columns.map(col => <Column key={col.id} props={col} />)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
