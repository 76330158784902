import React from 'react'
import Button from '../Button'
import TextLoop from 'react-text-loop'

import './styles.scss'

const Content = ({ props }) => {
  const {
    theme,
    contentJustification,
    applyTextLoop,
    textLoopLocation,
    textLoopDelay,
    textLoopInterval,
    textLoop,
    preHeadlineClass,
    preHeadlineId,
    preHeadline,
    preHeadlineType,
    headlineClass,
    headlineId,
    headlineType,
    headline,
    subHeadlineClass,
    subHeadlineId,
    subHeadlineType,
    subHeadline,
    detailsClass,
    detailsId,
    details,
    buttons,
  } = props
  const themeType = theme ? 'text-white' : ''
  const justification = `${contentJustification}`.toLowerCase()
  const PreHeadlineTag = `${preHeadlineType}`
  const HeadlineTag = `${headlineType}`
  const SubHeadlineTag = `${subHeadlineType}`
  return (
    <div className={`copy justification-${justification}`}>
      {preHeadline && preHeadline.length > 1 && (
        <PreHeadlineTag
          id={preHeadlineId}
          className={`preheadline ${preHeadlineClass} ${themeType}`}
        >
          {preHeadline}
        </PreHeadlineTag>
      )}
      {headline && headline.length > 1 && (
        <HeadlineTag
          id={headlineId}
          className={`headline ${headlineClass} ${themeType}`}
        >
          {applyTextLoop && textLoopLocation && (
            <TextLoop
              delay={textLoopDelay}
              interval={textLoopInterval}
              className={`textloop textloop--before ${themeType}`}
            >
              {textLoop.map((tl, i) => (
                <span key={i}>{tl}</span>
              ))}
            </TextLoop>
          )}
          {headline}
          {applyTextLoop && !textLoopLocation && (
            <TextLoop
              delay={textLoopDelay}
              interval={textLoopInterval}
              className={`textloop textloop--after ${themeType}`}
            >
              {textLoop.map((tl, i) => (
                <span key={i}>{tl}</span>
              ))}
            </TextLoop>
          )}
        </HeadlineTag>
      )}
      {subHeadline && subHeadline.length > 1 && (
        <SubHeadlineTag
          id={subHeadlineId}
          className={`subhead ${subHeadlineClass} ${themeType}`}
        >
          {subHeadline}
        </SubHeadlineTag>
      )}
      {details && details.childMarkdownRemark.html.length > 10 && (
        <span
          id={detailsId}
          className={`${detailsClass} ${themeType}`}
          dangerouslySetInnerHTML={{ __html: details.childMarkdownRemark.html }}
        />
      )}
      {buttons &&
        buttons.map(button => <Button key={button.id} props={button} />)}
    </div>
  )
}

export default Content
