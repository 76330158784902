import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/'
import Navigation from '../components/Navigation/'
import Hero from '../components/Hero/'
import Module from '../components/Module/'
import Footer from '../components/Footer/'

const PageTemplate = ({ data }) => {
  const {
    company,
    settings,
    seo,
    navigation,
    hero,
    modules,
    footer,
  } = data.contentfulPage

  return (
    <Layout settings={settings} seo={seo}>
      {settings.hasNavigation && (
        <Navigation company={company} settings={settings} props={navigation} />
      )}
      {settings.navigationHasSpacer && <div className="spacer py-5"></div>}
      <Fragment>
        {settings.hasHero && <Hero props={hero} settings={settings} />}
        {modules &&
          modules.map(mod => (
            <Module key={mod.name} props={mod} settings={settings} />
          ))}
      </Fragment>
      {settings.hasFooter && (
        <Footer company={company} settings={settings} props={footer} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      company {
        name
        address {
          childMarkdownRemark {
            html
            plainText
          }
        }
        email
        phone
        copyright
        socialLinks {
          id
          name
          buttonId
          buttonClass
          style
          type
          soft
          outline
          shape
          size
          title
          text
          link
          hasIcon
          icon
          iconLocation
          accessibility
          value
          noFollow
          newTab
          trackingId
          trackingPosition
        }
      }
      settings {
        name
        backgroundColor
        isBlogPost
        hasNavigation
        navigationTheme
        navigationBackground
        navigationHasShadow
        navigationHasSpacer
        navigationOnHero
        hasHero
        hasFooter
        footerMessage
      }
      seo {
        pageTitle
        description
        canonical
        robots
        twitterCard
        twitterSite
        twitterTitle
        twitterDescription
        twitterCreator
        twitterImage {
          file {
            url
          }
        }
        ogTitle
        ogUrl
        ogImage {
          file {
            url
          }
        }
        ogImageWidth
        ogImageHeight
        ogDescription
        ogSiteName
      }
      navigation {
        topBarLogo {
          imageId
          imageClass
          alt
          backgroundColor
          link
          file {
            description
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        topBarLogoLight {
          imageId
          imageClass
          alt
          backgroundColor
          link
          file {
            description
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        topBarLinks {
          id
          name
          buttonId
          buttonClass
          style
          type
          soft
          outline
          shape
          size
          title
          text
          link
          hasIcon
          icon
          iconLocation
          accessibility
          value
          noFollow
          newTab
          trackingId
          trackingPosition
        }
        # flyoutLogo {
        #   imageId
        #   imageClass
        #   alt
        #   backgroundColor
        #   link
        #   file {
        #     description
        #     fluid(maxWidth: 1800) {
        #       ...GatsbyContentfulFluid_withWebp_noBase64
        #     }
        #   }
        # }
        # flyoutActions {
        #   id
        #   name
        #   buttonId
        #   buttonClass
        #   style
        #   type
        #   soft
        #   outline
        #   shape
        #   size
        #   title
        #   text
        #   link
        #   hasIcon
        #   icon
        #   iconLocation
        #   accessibility
        #   value
        #   noFollow
        #   newTab
        #   trackingId
        #   trackingPosition
        # }
        # flyoutLinks {
        #   id
        #   name
        #   buttonId
        #   buttonClass
        #   style
        #   type
        #   soft
        #   outline
        #   shape
        #   size
        #   title
        #   text
        #   link
        #   hasIcon
        #   icon
        #   iconLocation
        #   accessibility
        #   value
        #   noFollow
        #   newTab
        #   trackingId
        #   trackingPosition
        # }
      }
      hero {
        name
        background
        backgroundImage {
          imageId
          imageClass
          alt
          backgroundColor
          link
          file {
            description
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        backgroundImageMask
        backgroundColor
        backgroundParallax
        verticalSpacing
        wrapperWidth
        columns {
          id
          name
          columnId
          columnClass
          columnWidth
          columnOffset
          columnUtility
          content {
            id
            contentType
            image {
              id
              imageId
              imageClass
              alt
              backgroundColor
              link
              file {
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            copy {
              id
              theme
              contentJustification
              applyTextLoop
              textLoopLocation
              textLoop
              textLoopDelay
              textLoopInterval
              preHeadlineClass
              preHeadlineId
              preHeadline
              preHeadlineType
              headlineClass
              headlineId
              headlineType
              headline
              subHeadlineClass
              subHeadlineId
              subHeadlineType
              subHeadline
              detailsClass
              detailsId
              details {
                childMarkdownRemark {
                  html
                }
              }
              buttons {
                id
                name
                buttonId
                buttonClass
                style
                type
                soft
                outline
                shape
                size
                title
                text
                link
                hasIcon
                icon
                iconLocation
                accessibility
                value
                noFollow
                newTab
                trackingId
                trackingPosition
              }
            }
            card {
              id
              featured
              link
              image {
                imageId
                imageClass
                alt
                backgroundColor
                link
                file {
                  description
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
              hasIcon
              iconStyle
              icon
              textAlign
              headline
              headlineType
              details {
                childMarkdownRemark {
                  html
                }
              }
              hoverEffect
              button {
                id
                name
                buttonId
                buttonClass
                style
                type
                soft
                outline
                shape
                size
                title
                text
                link
                hasIcon
                icon
                iconLocation
                accessibility
                value
                noFollow
                newTab
                trackingId
                trackingPosition
              }
            }
            form {
              formType
            }
          }
        }
        angledBottom
      }
      modules {
        id
        name
        moduleId
        moduleClass
        removeTopPadding
        verticalSpacing
        wrapperWidth
        background
        backgroundImage {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundColor
        columns {
          id
          name
          columnId
          columnClass
          columnWidth
          columnOffset
          columnUtility
          content {
            id
            contentType
            image {
              imageId
              imageClass
              alt
              backgroundColor
              link
              file {
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            copy {
              id
              theme
              contentJustification
              applyTextLoop
              textLoopLocation
              textLoop
              textLoopDelay
              textLoopInterval
              preHeadlineClass
              preHeadlineId
              preHeadline
              preHeadlineType
              headlineClass
              headlineId
              headlineType
              headline
              subHeadlineClass
              subHeadlineId
              subHeadlineType
              subHeadline
              detailsClass
              detailsId
              details {
                childMarkdownRemark {
                  html
                }
              }
              buttons {
                id
                name
                buttonId
                buttonClass
                style
                type
                soft
                outline
                shape
                size
                title
                text
                link
                hasIcon
                icon
                iconLocation
                accessibility
                value
                noFollow
                newTab
                trackingId
                trackingPosition
              }
            }
            card {
              id
              featured
              link
              image {
                imageId
                imageClass
                alt
                backgroundColor
                link
                file {
                  description
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
              hasIcon
              iconStyle
              icon
              textAlign
              headline
              headlineType
              details {
                childMarkdownRemark {
                  html
                }
              }
              hoverEffect
              button {
                id
                name
                buttonId
                buttonClass
                style
                type
                soft
                outline
                shape
                size
                title
                text
                link
                hasIcon
                icon
                iconLocation
                accessibility
                value
                noFollow
                newTab
                trackingId
                trackingPosition
              }
            }
            form {
              id
              formType
            }
            # post {
            #   id
            #   title
            #   slug
            #   publishDate(formatString: "MMMM DD, YYYY")
            #   publishDateISO: publishDate(formatString: "YYYY-MM-DD")
            #   tags {
            #     title
            #     id
            #     slug
            #   }
            #   heroImage {
            #     title
            #     fluid(maxWidth: 1800) {
            #       ...GatsbyContentfulFluid_withWebp_noBase64
            #     }
            #   }
            #   body {
            #     childMarkdownRemark {
            #       html
            #       excerpt(pruneLength: 320)
            #     }
            #   }
            # }
            jumbotron {
              id
              name
              theme
              verticalSpacing
              background
              backgroundColor
              backgroundImage {
                imageId
                imageClass
                alt
                backgroundColor
                link
                file {
                  description
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
              headline {
                childMarkdownRemark {
                  html
                  excerpt(pruneLength: 320)
                }
              }
              headlineType
              buttons {
                id
                name
                buttonId
                buttonClass
                style
                type
                soft
                outline
                shape
                size
                title
                text
                link
                hasIcon
                icon
                iconLocation
                accessibility
                value
                noFollow
                newTab
                trackingId
                trackingPosition
              }
            }
            cta {
              id
              isSlim
              theme
              icon
              iconBackground
              iconColor
              subhead
              headline
              copy {
                childMarkdownRemark {
                  html
                  excerpt(pruneLength: 320)
                }
              }
              button {
                id
                name
                buttonId
                buttonClass
                style
                type
                soft
                outline
                shape
                size
                title
                text
                link
                hasIcon
                icon
                iconLocation
                accessibility
                value
                noFollow
                newTab
                trackingId
                trackingPosition
              }
            }
          }
        }
      }
      footer {
        title
        logo {
          imageId
          imageClass
          alt
          backgroundColor
          link
          file {
            description
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
