import React from 'react'
import Button from '../Button'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import './styles.scss'

library.add(fab, fas)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggleFlyout = this.toggleFlyout.bind(this)
  }

  toggleFlyout() {
    this.setState(oldState => ({ isOpen: !oldState.isOpen }))
  }

  render() {
    const {
      topBarLogo,
      topBarLogoLight,
      topBarLinks,
      // flyoutLogo,
      // flyoutActions,
      // flyoutLinks,
    } = this.props.props

    // const {
    //   address,
    //   email,
    //   phone,
    //   socialLinks,
    // } = this.props.company;

    // const flyout = this.state.isOpen ? 'open' : 'closed';
    // const icon = this.state.isOpen ? 'times' : 'bars';
    // const expanded = this.state.isOpen ? 'true' : 'false';

    const shadow = this.props.settings.navigationHasShadow
      ? 'navbar-shadow'
      : ''
    const bgColor = this.props.settings.navigationBackground ? 'bg-white' : ''
    const navTheme = this.props.settings.navigationTheme
      ? 'dark-theme'
      : 'light-theme'
    const logo = this.props.settings.navigationTheme
      ? topBarLogo.file.fluid
      : topBarLogoLight.file.fluid

    // const addressPlainText = address.childMarkdownRemark.plainText.replace(/\s+/g, '+');

    // window.addEventListener('scroll', () => {
    //   if (window.scrollY > 50) {
    //     document.getElementById('navbar-main').classList.add('navbar-sticky')
    //     document.getElementById('navbar-main').classList.add('sticky')
    //   } else {
    //     document.getElementById('navbar-main').classList.remove('navbar-sticky')
    //     document.getElementById('navbar-main').classList.remove('sticky')
    //   }
    // })

    return (
      <nav
        id="navbar-main"
        className={`navigation__container ${shadow} ${bgColor} ${navTheme}`}
        role="navigation"
      >
        <a href="#main" title="Skip to main content" className="sr-only">
          Skip to main content
        </a>

        <div className="container-fluid navigation__container--topbar p-0 pb-2">
          <div className="col-3 d-flex align-items-center p-0">
            <Link to="/" title="Home">
              <Img
                fluid={logo}
                backgroundColor={'#eeeeee'}
                alt={topBarLogo.alt}
                className="logo"
              />
            </Link>
          </div>

          <div className="col-9 nav-items p-0">
            <ul className="">
              {topBarLinks.map(tbLink => (
                <li key={tbLink.id} className="nav-item">
                  <Button props={tbLink} />
                </li>
              ))}
            </ul>
            {/* <button
              data-lid="menu icon"
              data-lpos="navigation"
              className="menu-icon"
              onClick={this.toggleFlyout}
              aria-expanded={expanded}
            >
              <FontAwesomeIcon icon={['fas', icon]} size="lg" />
              <span className="sr-only">menu icon</span>
            </button> */}
          </div>
        </div>

        {/* <div className={`navigation__container--flyout ${flyout}`}>
          <div className="controls">
            <Link to="/" title="Home">
              <Img fluid={flyoutLogo.file.fluid} alt={flyoutLogo.alt} />
            </Link>
          </div>

          <div className="wrapper">
            <div className="actions">
              <div className="flyout-actions">
                <ul>
                  {flyoutActions.map(foAction => (
                    <li key={foAction.id}>
                      <Button props={foAction} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="card shadow-color-lg border-0">
              <div className="container pt-5">
                <div className="row">
                  <div className="col-md-6">
                    <ul className="link-actions">
                      {flyoutLinks.map(foLink => (
                        <li key={foLink.id}>
                          <Button props={foLink} />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="col-md-6 d-flex">
                    <div className="contact-details">
                      <div className="addresss">
                        <a
                          href={`https://google.com/maps/place/${addressPlainText}`}
                          title="Visit Us"
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <address>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: address.childMarkdownRemark.html,
                              }}
                            />
                          </address>
                        </a>
                      </div>
                      <div className="phone_email">
                        <p className="phone">
                          <a
                            href="tel:{phone}"
                            title="Call Us"
                            className="link"
                          >
                            {phone}
                          </a>
                        </p>

                        <p className="email">
                          <a
                            href="mailto:{email}"
                            title="Email Us"
                            className="link"
                          >
                            {email}
                          </a>
                        </p>
                      </div>
                      <div className="row py-3 social-links">
                        {socialLinks.map(soLink => {
                          return (
                            <a
                              key={soLink.id}
                              href={soLink.link}
                              title={soLink.title}
                              className={`btn rounded-circle btn-icon-only ${soLink.linkClass}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="btn-inner--icon">
                                <FontAwesomeIcon
                                  icon={['fab', soLink.icon]}
                                  size="lg"
                                />
                              </span>
                              <span className="sr-only">{soLink.text}</span>
                            </a>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </nav>
    )
  }
}

export default Navigation
