import React, { Fragment } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Copy from '../Copy/'
import Card from '../Card/'
import Post from '../Post'
import Jumbotron from '../Jumbotron'
import Cta from '../Cta'
import ContactForm from '../ContactForm/'

const Content = ({ props }) => {
  const {
    contentType,
    image,
    copy,
    card,
    form,
    post,
    jumbotron,
    cta
} = props

  return (
    <Fragment>
      {(() => {
        if (contentType === "image") {
          return image.map(img => (
            img.link && img.link.length > 1 ?
              <Link key={img.id} to={img.link}>
                <Img
                  fluid={img.file.fluid}
                  backgroundColor={img.backgroundColor}
                  alt={img.alt}
                />
              </Link>
              :
              <Img key={img.key}
                fluid={img.file.fluid}
                backgroundColor={img.backgroundColor}
                alt={img.alt}
              />
          ))
        } else if (contentType === "copy") {
          return copy.map(cp => (
            <Copy key={cp.id} props={cp}/>
          ))
        } else if(contentType === "card") {
          return card.map(cd => (
            <Card key={cd.id} props={cd}/>
          ))
        } else if(contentType === "form") {
          if(form.formType == "contact") {
            return <ContactForm />
          }
        } else if(contentType === "post") {
          return <Post col='12' props={post}/>
        } else if(contentType === "jumbotron") {
          return <Jumbotron props={jumbotron}/>
        } else if(contentType === "cta") {
          return <Cta props={cta}/>
        }
      })()}
    </Fragment>
  )
}

export default Content
