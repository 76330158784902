import React from 'react'
import LazyLoad from 'react-lazyload'
import Button from '../Button'

import './styles.scss'

const Jumbotron = ({ props }) => {
  const {
    theme,
    verticalSpacing,
    background,
    backgroundColor,
    backgroundImage,
    headline,
    headlineType,
    buttons,
  } = props

  let text
  let bgColor = ''
  let altText

  if (!background) {
    bgColor = !!backgroundColor ? backgroundColor.replace(' ', '-') : 'white'
  } else {
    altText =
      backgroundImage.alt !== null
        ? backgroundImage.alt
        : backgroundImage.file.description
  }

  if (!theme) {
    background = 'bg-dark'
    text = 'text-white'
  }

  const HeadlineTag = `${headlineType}`

  return (
    <div className={`jumbotron shadow-lg mb-0 bg-${bgColor}`}>
      {background && (
        <LazyLoad>
          <img
            src={backgroundImage.file.fluid.src}
            alt={altText}
            className="img-fluid img-as-bg"
          />
        </LazyLoad>
      )}

      <div className={`container py-${verticalSpacing}`}>
        <div className="row pt-6 pb-8">
          <div className="col-sm-12 col-md-10">
            <HeadlineTag
              className={`${HeadlineTag} lh-170 ${text}`}
              dangerouslySetInnerHTML={{
                __html: headline.childMarkdownRemark.html,
              }}
            />
            <div className="mt-5">
              {buttons &&
                buttons.map(button => (
                  <Button key={button.id} props={button} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Jumbotron
