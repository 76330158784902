import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import LazyLoad from 'react-lazyload'

const Post = ({ col, props }) => {
  const { slug, heroImage, title, publishDate, body } = props

  return (
    <div className={`col-md-${col} col-sm-12`}>
      <div className={`card card-overlay blog-card card-hover-overlay`}>
        <figure className="figure">
          {/* <Img fluid={heroImage.fluid} backgroundColor={'#eeeeee'} /> alt={title} */}
          <LazyLoad>
            <img src={heroImage.fluid.src} alt={title} className="img-fluid" />
          </LazyLoad>
        </figure>

        <div className="card-img-overlay d-flex flex-column align-items-center p-0">
          <div className="overlay-text w-75 mt-auto p-4">
            <h3>{title}</h3>
            <time dateTime={publishDate}>{publishDate}</time>
            <div
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.excerpt,
              }}
            />
          </div>
          <div className="overlay-actions w-100 card-footer mt-auto d-flex justify-content-between align-items-center">
            <div>
              <Link to={`/blog/${slug}/`} className="link">
                Read More about {title}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
