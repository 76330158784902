import React from 'react'
import Button from '../Button'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

const Cta = ({ props }) => {
  const {
    isSlim,
    theme,
    icon,
    iconBackground,
    iconColor,
    subhead,
    headline,
    copy,
    button
  } = props

  let background;
  let text;

  if(!theme) {
    background = 'bg-section-dark';
    text = 'text-white';
  }
    
  if(isSlim) {
    return (
      <div className={`card ${background} shadow-lg border-0 zindex-100`}>
        <div className="card-body px-5 py-5 text-center text-md-left">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h4 className={`h5 mb-2 ${text}`}>{headline}</h4>
              <p className={`mb-0 ${text}`} dangerouslySetInnerHTML={{ __html: copy.childMarkdownRemark.html }} />
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0 text-align-right">
              <Button props={button} />
            </div>
          </div>
        </div>
      </div>
    )
    
  } else {

    return (
      <div className={`card ${background} border-0 rounded-lg`}>
        <div className="card-body px-5">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center align-items-center mb-3">
                <div>
                  <div className={`icon icon-sm icon-shape bg-${iconBackground} text-${iconColor} rounded-circle mr-3`}>
                    <FontAwesomeIcon icon={['fas', icon]} size="lg"/>
                  </div>
                </div>
                <span className={`h6 ${text} mb-0`}>{subhead}</span>
              </div>
              <h4 className={`h5 ${text} pt-4`}>{headline}</h4>
              <p className={`${text} opacity-8`} dangerouslySetInnerHTML={{ __html: copy.childMarkdownRemark.html }} />
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0 pr-4 pb-3 d-flex align-items-end justify-content-md-end">
              <Button props={button} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Cta